import React, { useState } from "react";
import { Switch } from "@headlessui/react";

const Settings = ({ data, onStatusChange }) => {
  const userStatusToggle = (condition, classes) => (condition ? `${classes} ${condition}` : classes);

  // Maintain separate state for each switch
  const [stopTrading, setStopTrading] = useState(data.setting_stop_trading);
  const [closeAllTrading, setCloseAllTrading] = useState(data.setting_close_all_trading);

  // Function to handle switch toggle
  const handleSwitchToggle = (key) => {
    if (key === "setting_stop_trading") {
      setStopTrading(!stopTrading);
      onStatusChange(data.setting_id, { [key]: !stopTrading,setting_close_all_trading:closeAllTrading });
    } else if (key === "setting_close_all_trading") {
      setCloseAllTrading(!closeAllTrading);
      onStatusChange(data.setting_id, { [key]: !closeAllTrading,setting_stop_trading:stopTrading });
    }
  };

  return (
    <>
      {Object.entries(data).map(([key, value]) => {
        if (key === "setting_stop_trading" || key === "setting_close_all_trading") {
          const label = key === "setting_stop_trading" ? "Stop Trading" : "Close All Trades";
          return (
            <div key={key} className="flex flex-col items-center">
              <span className="text-xs font-medium">{label}</span>
              <Switch
                checked={key === "setting_stop_trading" ? stopTrading : closeAllTrading}
                onChange={() => handleSwitchToggle(key)}
                className={userStatusToggle(
                  key === "setting_stop_trading" ? stopTrading ? "bg-[#27ff10]" : "bg-gray-200" : closeAllTrading ? "bg-[#27ff10]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={userStatusToggle(
                    key === "setting_stop_trading" ? stopTrading ? "translate-x-5" : "translate-x-0" : closeAllTrading ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default Settings;

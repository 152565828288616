import React, { useRef, useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/tables/table";
import { copy_trade_columns } from "../../../../components/tables/tableheader";

import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import CopyAccountServices from "../../../../ApiServices/CopyAccountServices";
import { toast } from "react-toast";

import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../../helper/commonHelper";


function CopyTrade() {
  const pages = [{ title: "Copy Trade List", href: "/copy-trade", module_id: 2 }];
  const [isLoading, setIsLoading] = useState(true);

  const [accountList, setAccountList] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    mua_acc_login: "",
    mua_id: null,
  });


  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }

  }, []);

  useEffect(() => {
    document.title = "EdgeFin •  Copy Trades";
    getAllCopyTradeAcc(3);
  }, []);

  const getAllCopyTradeAcc = async (payload) => {
    setIsLoading(true);
    try {
      const res = await CopyAccountServices.getAllCopyTrade(payload)
      if (res.status) {
        setAccountList(res.data.data);
        const filtered = res?.data?.data?.filter((item) => item.mua_acc_ms_is_master == true);
        if (filtered?.length > 0) {
          setFilterData(filtered[0]);
          console.log("filtered=>", filtered)
        }
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }

  const onStatusUpdate = async (mua_id, acc_status, mua_acc_login) => {
    let payload = {
      mua_id: mua_id,
      mua_acc_login: mua_acc_login,
      mua_acc_ms_is_master: true,
    }
    let { data, status } = await CopyAccountServices.saveCopyAccount(payload)
    if (status === 200) {
      if (data.status) {

        toast.success(data.message);
        getAllCopyTradeAcc(3);
      }
    } else {

      toast.error(data.message);
    }
    setConfirmationModal({ mua_id: null, mua_acc_login: "", status: false });

  }

  const OnChangeAccountType = (mua_id, acc_status, mua_acc_login) => {
    let title = "Are you sure you want to modify?";
    title = "Are you sure you want to Activate Master For Account No : " + (mua_acc_login) + " One Account Already Have Faster Function On " + (filterData?.mua_acc_login);
    setConfirmationModal({ mua_id, acc_status, mua_acc_login, status: true, title });
  };


  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4">
      <ConfirmationModal
        title={confirmationModal?.title ? confirmationModal?.title : "Are you sure you want to modify settings?"}
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() => {
          onStatusUpdate(
            confirmationModal.mua_id,
            confirmationModal.acc_status,
            confirmationModal.mua_acc_login
          );
          console.log("confirmationModal.mua_acc_login=>", confirmationModal);
        }
        }
        setOpen={setConfirmationModal}
      />


      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Copy Trade List</h1>
      </div>


      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={copy_trade_columns({
            OnChangeAccountType,
            allowedActions,
          })}
          data={accountList}
          is_toggle={false}
        />
      )}


    </div>
  );
}
export default CopyTrade;
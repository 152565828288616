import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { licenseSchema } from '../../../../schemas';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import MasterServices from '../../../../ApiServices/MasterServices';
import makeAnimated from "react-select/animated";
import { Switch } from '@headlessui/react'
import { toast } from 'react-toast';
import moment from 'moment';
import { FaSpinner } from "react-icons/fa";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../../helper/commonHelper";

import WhopServices from "../../../../ApiServices/WhopServices";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AddLicense = () => {
  const pages = [{ title: "Add License", href: "/add-license", module_id: 40 }];

  const [productList, setProductList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedProductId, setSelectedProductId] = useState({ value: '', label: '' });
  const [selectedUserId, setSelectedUserId] = useState({ value: '', label: '' });
  const { wp_lic_id: wp_lic_id } = useParams()
  const animatedComponents = makeAnimated();
  const navigate = useNavigate()
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  useEffect(() => {
    getAllCustomer(1);
    getAllProduct(1);
    if (wp_lic_id) {
      setTimeout(() => {
        getSingleLicense(wp_lic_id);
      }, 3000)
    }
  }, [])

  const getAllCustomer = async (payload) => {
    try {
      const res = await WhopServices.getAllWhopUser(payload)
      if (res.status) {
        setUserList(res.data.data);
      }
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
    }
  }
  const getAllProduct = async (payload) => {
    try {
      const res = await WhopServices.getAllWhopProduct(payload)
      if (res.status) {
        setProductList(res.data.data);
      }
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
    }
  }

  let initialValues = {
    wp_lic_id: null,
    fk_wp_user_id: "",
    fk_wp_prod_id: "",
    lic_mem_id: "",
    lic_affiliate_username: "",
    lic_checkout_id: "",
    lic_company_buyer_id: "",
    license_key: "",
    lic_manage_url: "",
    lic_page_id: "",
    lic_plan_id: "",
    lic_product_id: "",
    lic_status: "",
    lic_user_id: "",
    lic_quantity: "",
    lic_metadata: "",
    lic_cancel_at_period_end: "",
    lic_marketplace: "",
    lic_valid: "",
    lic_renewal_period_end: "",
    lic_renewal_period_start: "",
    lic_created_at: "",
    lic_expires_at: "",
    lic_is_active: true,
  }
  const [licenseForm, setLicenseForm] = useState(initialValues)

  const getSingleLicense = (wp_lic_id) => {
    setIsLoading(true)
    setSelectedProductId('');
    setSelectedUserId('');
    values.fk_wp_user_id = ""
    values.fk_wp_prod_id = ""
    WhopServices.getAllWhopSingleLicense(wp_lic_id).then((res) => {
      const { data, status } = res;
      if (status) {
        console.log("newLicense", data.data)

        initialValues = {
          wp_lic_id: parseInt(wp_lic_id),
          fk_wp_user_id: data?.data?.fk_wp_user_id,
          fk_wp_prod_id: data?.data?.fk_wp_prod_id,
          lic_mem_id: data?.data?.lic_mem_id,
          lic_affiliate_username: data?.data?.lic_affiliate_username,
          lic_checkout_id: data?.data?.lic_checkout_id,
          lic_company_buyer_id: data?.data?.lic_company_buyer_id,
          license_key: data?.data?.license_key,
          lic_manage_url: data?.data?.lic_manage_url,
          lic_page_id: data?.data?.lic_page_id,
          lic_plan_id: data?.data?.lic_plan_id,
          lic_product_id: data?.data?.lic_product_id,
          lic_status: data?.data?.lic_status,
          lic_user_id: data?.data?.lic_user_id,
          lic_quantity: data?.data?.lic_quantity,
          lic_metadata: data?.data?.lic_metadata,
          lic_cancel_at_period_end: data?.data?.lic_cancel_at_period_end,
          lic_marketplace: data?.data?.lic_marketplace,
          lic_valid: data?.data?.lic_valid,
          lic_renewal_period_end: moment(data?.data?.lic_renewal_period_end).format("YYYY-MM-DD"),
          lic_renewal_period_start: moment(data?.data?.lic_renewal_period_start).format("YYYY-MM-DD"),
          lic_created_at: moment(data?.data?.lic_created_at).format("YYYY-MM-DD"),
          lic_expires_at: moment(data?.data?.lic_expires_at).format("YYYY-MM-DD"),
          lic_is_active: data?.data?.lic_is_active,
        }
        setLicenseForm(initialValues)
        if (Array.isArray(productList) && productList.length > 0) {
          let product = productList.filter(v => v.value == initialValues.fk_wp_prod_id)
          if (product.length > 0) {
            setSelectedProductId({ value: product[0].value, label: product[0].label })
          }
        }
        if (Array.isArray(userList) && userList.length > 0) {
          let User = userList.filter(v => v.value == initialValues.fk_wp_user_id)
          if (User.length > 0) {
            setSelectedUserId({ value: User[0].value, label: User[0].label })
          }
        }
      }
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)
      setIsLoading(false)
    });
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: licenseForm,
      validationSchema: licenseSchema,
      onSubmit: async (values, action) => {
        console.log("license_dob", values.license_dob)
        let body = {
          wp_lic_id: wp_lic_id ? wp_lic_id : null,
          fk_wp_user_id: values.fk_wp_user_id,
          fk_wp_prod_id: values.fk_wp_prod_id,
          lic_mem_id: values.lic_mem_id,
          lic_affiliate_username: values.lic_affiliate_username,
          lic_checkout_id: values.lic_checkout_id,
          lic_company_buyer_id: values.lic_company_buyer_id,
          license_key: values.license_key,
          lic_manage_url: values.lic_manage_url,
          lic_page_id: values.lic_page_id,
          lic_plan_id: values.lic_plan_id,
          lic_product_id: values.lic_product_id,
          lic_status: values.lic_status,
          lic_user_id: values.lic_user_id,
          lic_quantity: values.lic_quantity,
          lic_metadata: values.lic_metadata,
          lic_cancel_at_period_end: values.lic_cancel_at_period_end,
          lic_marketplace: values.lic_marketplace,
          lic_valid: values.lic_valid,
          lic_renewal_period_end: values.lic_renewal_period_end,
          lic_renewal_period_start: values.lic_renewal_period_start,
          lic_created_at: values.lic_created_at,
          lic_expires_at: values.lic_expires_at,
          lic_is_active: true,
        };
        console.log("body", body)
        if (body.lic_affiliate_username != undefined || body.lic_affiliate_username != null || body.lic_affiliate_username != "") {
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          const response = await WhopServices.SaveWhopLicense(body);
          if (response.data.status) {
            action.resetForm();
            setLicenseForm(initialValues);
            toast.success(response.data.message);
            setDisableSubmitButton(false)
            navigate("/license");
          } else {
            toast.error(response.data.message);
            setDisableSubmitButton(false)
          }
        } else {
          setDisableSubmitButton(false)

          action.resetForm();
          setDisableSubmitButton(false)
        }
      },
    });

  return (
    <>
      {!isLoading ? (<div>
        <Breadcrumb pages={pages} />
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">{wp_lic_id?"Update":"Add"} License</h1>
        </div>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="grid grid-cols-3 gap-3 mt-3 lg:w-full w-full">
              <div>
                <label htmlFor="lic_affiliate_username" className="block text-sm font-medium leading-6 text-gray-900">
                  Name<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    value={values.lic_affiliate_username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="License Name"
                    name="lic_affiliate_username"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.lic_affiliate_username &&
                    touched.lic_affiliate_username ? (
                    <p className="text-red-600 text-sm">
                      {errors.lic_affiliate_username.replace("lic_affiliate_username", "License Name")}
                    </p>
                  ) : null}
                </div>
              </div>

              <div>
                <label
                  htmlFor="lic_quantity"
                  className="block text-sm font-medium text-gray-900 mb-1"
                >
                  Quantity
                  <span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    value={values.lic_quantity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    placeholder="Quantity"
                    name="lic_quantity"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.lic_quantity &&
                    touched.lic_quantity ? (
                    <p className="text-red-600 text-sm">
                      {errors.lic_quantity.replace("lic_quantity", "Quantity")}
                    </p>
                  ) : null}
                </div>
              </div>

              {userList && userList.length > 0 ? (<div>
                <label
                  htmlFor="fk_wp_user_id"
                  className="block text-sm font-medium text-gray-900 mb-1"
                >
                  Select User
                  <span className="text-red-600">*</span>
                </label>
                <div className="">
                  <Select
                    name="user_name"
                    id="wp_user_id"
                    menuPlacement="top"
                    placeholder={
                      <span>
                        Select User
                        <span className="text-red-600">*</span>
                      </span>
                    }
                    value={selectedUserId}
                    onChange={(e) => {
                      console.log(e);
                      values.fk_wp_user_id = e.value;
                      setSelectedUserId(e);
                    }}
                    components={animatedComponents}
                    options={
                      userList
                        ? userList.map((v) => ({
                          value: v.wp_user_id,
                          label: v.user_name,
                        }))
                        : []
                    }
                    classNamePrefix="select"
                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />

                  {errors.fk_wp_user_id && touched.fk_wp_user_id ? (
                    <div className="text-sm text-red-600">
                      {errors.fk_wp_user_id.replace("fk_wp_user_id", "User")}
                    </div>
                  ) : null}
                </div>
              </div>) : (<></>)}

              {productList && productList.length > 0 ? (<div>
                <label
                  htmlFor="fk_wp_prod_id"
                  className="block text-sm font-medium text-gray-900 mb-1"
                >
                  Select Product
                  <span className="text-red-600">*</span>
                </label>
                <div className="">
                  <Select
                    name="prod_name"
                    id="wp_prod_id"
                    menuPlacement="top"
                    placeholder={
                      <span>
                        Select Product
                        <span className="text-red-600">*</span>
                      </span>
                    }
                    value={selectedProductId}
                    onChange={(e) => {
                      console.log(e);
                      values.fk_wp_prod_id = e.value;
                      setSelectedProductId(e);
                    }}
                    components={animatedComponents}
                    options={
                      productList
                        ? productList.map((v) => ({
                          value: v.wp_prod_id,
                          label: v.prod_name,
                        }))
                        : []
                    }
                    classNamePrefix="select"
                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />

                  {errors.fk_wp_prod_id && touched.fk_wp_prod_id ? (
                    <div className="text-sm text-red-600">
                      {errors.fk_wp_prod_id.replace("fk_wp_prod_id", "Product Name")}
                    </div>
                  ) : null}
                </div>
              </div>) : (<></>)}
              <div>
                <label
                  htmlFor="lic_renewal_period_start"
                  className="block text-sm font-medium text-gray-900 mb-1"
                >
                  Renewal Period Start
                  <span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    value={values.lic_renewal_period_start}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="date"
                    placeholder="Renewal Period Start"
                    name="lic_renewal_period_start"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.lic_renewal_period_start &&
                    touched.lic_renewal_period_start ? (
                    <p className="text-red-600 text-sm">
                      {errors.lic_renewal_period_start.replace("lic_renewal_period_start", "Renewal Period Start")}
                    </p>
                  ) : null}
                </div>
              </div>

              <div>
                <label
                  htmlFor="lic_renewal_period_end"
                  className="block text-sm font-medium text-gray-900 mb-1"
                >
                  Renewal Period End
                  <span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    value={values.lic_renewal_period_end}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="date"
                    placeholder="Renewal Period End"
                    name="lic_renewal_period_end"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.lic_renewal_period_end &&
                    touched.lic_renewal_period_end ? (
                    <p className="text-red-600 text-sm">
                      {errors.lic_renewal_period_end.replace("lic_renewal_period_end", "Renewal Period End")}
                    </p>
                  ) : null}
                </div>
              </div>

              <div>
                <label
                  htmlFor="lic_created_at"
                  className="block text-sm font-medium text-gray-900 mb-1"
                >
                  Created Date
                  <span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    value={values.lic_created_at}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="date"
                    placeholder="Created Date"
                    name="lic_created_at"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.lic_created_at &&
                    touched.lic_created_at ? (
                    <p className="text-red-600 text-sm">
                      {errors.lic_created_at.replace("lic_created_at", "Created Date")}
                    </p>
                  ) : null}
                </div>
              </div>

              <div>
                <label
                  htmlFor="lic_expires_at"
                  className="block text-sm font-medium text-gray-900 mb-1"
                >
                  Expired Date
                  <span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    value={values.lic_expires_at}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="date"
                    placeholder="Expired Date"
                    name="lic_expires_at"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.lic_expires_at &&
                    touched.lic_expires_at ? (
                    <p className="text-red-600 text-sm">
                      {errors.lic_expires_at.replace("lic_expires_at", "Expired Date")}
                    </p>
                  ) : null}
                </div>
              </div>

            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              {errorMessage ? (<div className="basis-1/2 text-red-500 text-sm">
                {errorMessage !== "" &&
                  "Error: " + errorMessage}
              </div>) : (null)}
              <button
                disabled={disableSubmitButton}
                type="submit"
                className="inline-flex items-center justify-center rounded-md bbt bg-gradient-to-b from-[#dbff06] to-[#27ff10] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
              >
                {wp_lic_id ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Save")}
              </button>
            </div>
          </form>
        </div>

      </div>) : (

        <FallingLinesLoader />
      )}</>
  )
}

export default AddLicense